import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

export default function InterviewSkills() {
  return (
    <>
      <Helmet>
        <title>
          School Holiday Public Speaking Program for Children | Public Speaking
          Academy
        </title>
        <meta
          name="description"
          content="Led by Darren Tay, our public speaking training course coaches you effective communication and presentation skills in group. Contact us at 6443 4049 today!"
        ></meta>
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>INTERVIEW SKILLS PROGRAMME</div>
            <div>
              Home / Courses & Programs / Public Speaking for Kids / Interview
              Skills
            </div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-x-10">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-3xl font-bold text-primary uppercase">
                MARCH SCHOOL HOLIDAY INTERVIEW SKILLS PROGRAMME FOR CHILDREN
              </h1>
              <h2 class="font-primary text-xl lg:text-2xl font-bold text-primary uppercase py-5">
                A 2-DAY INTENSIVE, INTERPERSONAL COMMUNICATION HOLIDAY CAMP FOR
                KIDS
              </h2>
              <div>
                <img
                  src="/images/Kids intensive - featured pic-min.png"
                  alt="MARCH SCHOOL HOLIDAY INTERVIEW SKILLS PROGRAMME FOR CHILDREN"
                />
                <h2 class="font-primary text-xl lg:text-2xl font-bold text-primary pt-5">
                  Have Your Child Ace His/ Her Next High-Stakes Interview
                  Skilfully & Confidently!
                </h2>
                <p class="font-text text-lg py-5">
                  Preparing for school admission interviews, scholarship/
                  internship interviews, or CCA leadership prefectorial board
                  elections can be nerve-wracking, especially when our young
                  ones almost do not getting any interview skills training from
                  school at all. Finding the right guidance to help your child
                  be outstanding amongst the competition is equally challenging.
                  A long-term commitment might not suit everyone, but we have
                  the perfect solution – the Interview Skills Mastery (ISM)!
                  Whether your child is gearing up for their first big interview
                  (e.g. getting into their dream school) or needs a boost in
                  skills & confidence to ace their subsequent ones, our
                  Interview Skills Mastery (ISM) is the answer. Running since
                  2016, this signature holiday camp by the Public Speaking
                  Academy equips learners with the communication tools and
                  strategies to stand out in interviews and beyond.
                </p>
                <img
                  src="/images/interview-skills-1.png"
                  alt="image of how interview skills training can lead to the opening of doors of opportunities"
                />
                <p class="pb-5 pt-5 text-xl font-bold">
                  Introducing the Interview Skills Mastery (ISM) Programme
                </p>
                <p class="pb-5 font-text text-lg">
                  (A tailored only-in March holiday Communication Skills
                  workshop for children ages 9–12 & 13–18) The Interview Skills
                  Mastery program is a 2-day intensive workshop on interpersonal
                  communication, self-pitching and interview skills, designed to
                  prepare young learners for success in high-stakes interview
                  situations. From formulating compelling responses swiftly, to
                  mastering effective & impressive body language, we focus on 6
                  core areas of communication, including interview skills,
                  storytelling, and building stage confidence. Led by Darren
                  Tay, World Champion of Public Speaking 2016, and our Senior
                  Trainers, this programme offers a deep dive into essential
                  skills that will set your child apart.
                </p>

                <img
                  src="/images/interview-skills-2.jpg"
                  alt="image of master trainer darren tay sharing a secret about our interview skills training program"
                />
                <p class="pb-5 font-text text-lg pt-5">
                  We will let you in on a secret – the main trainer of ISM,
                  Darren, has been a highly sought after 1-to-1 interview skills
                  trainer and prepper since 2016; it has always been private
                  training sessions where Darren imparts the interview tips and
                  strategies and confidence-building advantages to these
                  students. This is the first program specifically designed and
                  conducted by Darren to let more students benefit from the
                  interview skills mastery secrets!
                </p>

                <p class="pb-5 font-text text-lg">
                  There will be plenty of interview opportunities or
                  “challenges” (depends on how you perceive it) down the road
                  for your child. This is a preparatory program that, we
                  believe, every student should attend at least once in their
                  lifetime (so that they will be better prepared for all
                  subsequent interviews in their future ahead)!
                </p>

                <p class="pb-5 font-text text-lg">
                  Our aim is to enable graduates of the Interview Skills Mastery
                  (ISM) program to walk away from every subsequent interview
                  (whether it’s for DSA, internship, scholarship, CCA leadership
                  committee, prefectorial board, and etc.) feeling and knowing
                  that they have:
                </p>

                <img
                  src="/images/interview-skills-3.png"
                  alt="image of a person walking out of an interview session feeling confident"
                />
                <hr />

                <h2 class="font-primary text-xl lg:text-2xl font-bold text-primary py-5">
                  What to Expect in the “ISM” Holiday Camp
                </h2>
                <h3 class="font-primary text-xl lg:text-xl font-bold text-primary">
                  Catch a glimpse of How Your Child is Going to be Guided Along
                  the insider tips!
                </h3>

                <div className="py-5">
                  <div className="aspect-video">
                    <iframe
                      class="w-full h-full"
                      src="https://www.youtube.com/embed/cbRctQf0cv8"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="mx-auto">
                <h2 class="font-primary text-3xl font-bold text-primary text-center py-5">
                  The Interview Skills Training Camp Your Child Needs This March
                  Holiday Before DSA season!
                </h2>
                <div className="p-5 bg-blue-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    Theory Building
                  </h3>
                  <p className="text-lg text-center ">
                    Learners receive proprietary materials and are guided
                    step-by-step through proven communication and interview
                    techniques. Our professional trainers use engaging methods
                    like quizzes, group discussions, and interactive challenges
                    to keep sessions dynamic.
                  </p>
                </div>
                <div className="my-2">
                  <svg
                    className="mx-auto text-center"
                    width="24"
                    height="58"
                    viewBox="0 0 24 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9393 57.0607C11.5251 57.6464 12.4749 57.6464 13.0607 57.0607L22.6066 47.5147C23.1924 46.9289 23.1924 45.9792 22.6066 45.3934C22.0208 44.8076 21.0711 44.8076 20.4853 45.3934L12 53.8787L3.51472 45.3934C2.92893 44.8076 1.97918 44.8076 1.3934 45.3934C0.807609 45.9792 0.807609 46.9289 1.3934 47.5147L10.9393 57.0607ZM10.5 -6.55671e-08L10.5 56L13.5 56L13.5 6.55671e-08L10.5 -6.55671e-08Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="p-5 bg-blue-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    Breakout Practice
                  </h3>
                  <p className="text-lg text-center ">
                    Participants apply what they’ve learned in small, focused
                    breakout sessions. These allow young communicators to refine
                    their techniques in a safe and supportive environment.
                  </p>
                </div>
                <div className="my-2">
                  <svg
                    className="mx-auto text-center"
                    width="24"
                    height="58"
                    viewBox="0 0 24 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9393 57.0607C11.5251 57.6464 12.4749 57.6464 13.0607 57.0607L22.6066 47.5147C23.1924 46.9289 23.1924 45.9792 22.6066 45.3934C22.0208 44.8076 21.0711 44.8076 20.4853 45.3934L12 53.8787L3.51472 45.3934C2.92893 44.8076 1.97918 44.8076 1.3934 45.3934C0.807609 45.9792 0.807609 46.9289 1.3934 47.5147L10.9393 57.0607ZM10.5 -6.55671e-08L10.5 56L13.5 56L13.5 6.55671e-08L10.5 -6.55671e-08Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="p-5 bg-blue-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    Mock ‘Interview Challenge’ Stations
                  </h3>
                  <p className="text-lg text-center ">
                    Learners culminate the workshop by tackling multiple
                    stations that mimic real-life interview situations
                    (scenario-based familiarization), receiving invaluable
                    feedback and confidence in handling multiple interview
                    scenarios.
                  </p>
                </div>
                <div>
                  <p class="py-8 font-text text-lg">
                    The ISM March holiday camp’s syllabus focuses on 6
                    communication & public speaking domains that apply to our
                    young learners’ day-to-day in school, at home, and even in
                    the future. From speech-crafting techniques to interview
                    skills that we will be imparting in this holiday workshop,
                    your child will explore the broad techniques and tips with
                    the guidance of Singapore’s first Public Speaking World
                    Champion and our experienced senior trainers:
                  </p>
                </div>

                <div className="py-5">
                  <h2 class="text-center text-xl font-bold pb-1">
                    The Programme Syllabus (***Syllabus to be confirmed)
                  </h2>
                  <h2 class="text-center text-4xl font-bold uppercase text-primary pb-1">
                    6 communication domains
                  </h2>
                  <h3 class="text-center text-xl font-bold uppercase text-primary pb-1">
                    (CORE FOCUS THROUGHOUT THE 2-DAY HOLIDAY CAMP)
                  </h3>
                  <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-5 my-14">
                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 border border-dashed border-cyan-200 bg-cyan-100 rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white rounded-md text-md bg-primary font-primary">
                            Body Language
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            In this camp, explore & learn foundational body
                            langauge and vocal variety elements.
                          </p>
                          <div className="flex flex-row">
                            <ul className="px-2 list-none">
                              <li>Stage Positioning</li>
                              <li>Power Poses</li>
                              <li>Vocal Variety</li>
                              <li>Eye Contact</li>
                            </ul>
                            <ul className="px-2 list-none">
                              <li>Power Pauses</li>
                              <li>Audience Survey & Participation</li>
                              <li>Facial Expressions</li>
                              <li>Articulation</li>
                              <li>Linguistics</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 bg-orange-100 border border-orange-200 border-dashed rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white bg-orange-600 rounded-md text-md font-primary">
                            In - Class Presentation
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            In this camp, learn how to enliven in-class
                            presentations to engage the audience
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Story-Crafting</li>
                              <li>Speech Structure</li>
                              <li>Presenting with visual aids</li>
                              <li>Edge of the Seats: 8 Step Blueprint</li>
                              <li>Audience Engagement</li>
                              <li>Engagement</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 border border-dashed border-cyan-200 bg-cyan-100 rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white rounded-md text-md bg-primary font-primary">
                            Charisma + Dealing With Stage Fright
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            In this camp, learn to manage and tackle stage
                            fright/anxiety with charisma
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Advanced Power Poses</li>
                              <li>Visualization for Stage Confidence</li>
                              <li>Rehearsing, Practising Tips</li>
                              <li>Charisma-Building</li>
                              <li>Mindfulness Practice</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-5 my-14">
                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 bg-orange-100 border border-orange-200 border-dashed rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white bg-orange-600 rounded-md text-md font-primary">
                            Interview Mastery
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            In this camp, master interview common questions with
                            confidence
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Interview Preparation</li>
                              <li>Top 10 Toughest Interview Questions</li>
                              <li>Acing Group Interviews</li>
                              <li>Answering Techniques</li>
                              <li>Persuasion & Follow-Up</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 border border-dashed bg-cyan-100 border-cyan-200 rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white rounded-md bg-primary text-md font-primary">
                            Impromptu Speaking
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            In this camp, learn to think and respond
                            off-the-cuff in managing impromptu settings
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Tell a Captivating Story and Make a Point</li>
                              <li>Use Interrogative Pronouns</li>
                              <li>Past, Present, Future model</li>
                              <li>Pendulum Method</li>
                              <li>P.E.E.L Approach</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 bg-orange-100 border border-orange-200 border-dashed rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white bg-orange-600 rounded-md text-md font-primary">
                            Oral Exam
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            In this camp, learn how to apply public speaking
                            techniques to Oral Examination settings
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Pronunciation & Enunciation</li>
                              <li>Diction, Tonality, Context</li>
                              <li>Fluency, Purpose, Expressiveness</li>
                              <li>Development of Rich Points</li>
                              <li>Mastering Question Types</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section id="review">
                  <div className="w-full py-5 my-5 bg-slate-100 rounded-xl">
                    <div className="pt-10 pb-5">
                      <h2 className="text-xl font-bold text-center font-primary">
                        What Our Students Have To Say About How Training Is Like
                        With Mr Darren:
                      </h2>
                    </div>
                    <div className="flex flex-col px-5 md:flex-row">
                      <div className="px-5 pb-10 md:w-1/2">
                        <div className="aspect-video">
                          <iframe
                            class="w-full h-full"
                            src="https://www.youtube.com/embed/Ai5BFT2plXc"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                      <div className="px-5 pb-10 md:w-1/2">
                        <div className="aspect-video">
                          <iframe
                            class="w-full h-full"
                            src="https://www.youtube.com/embed/J1BZp26CXV4"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="block-3">
                  <div>
                    <p class="text-2xl font-bold py-2 italic">
                      ISM Kids March Holiday Camp Details - How Do I Enrol My
                      Child?
                    </p>
                    <p class="pb-5 font-text text-lg">
                      The Interview Skills Mastery programme is only conducted
                      at our SAFRA Tampines Branch (1A Tampines Street 92
                      #01-13B (SAFRA Tampines) Singapore-528882), with the
                      following schedule (1 free lunch provided per day during
                      the camp, per pax):
                    </p>
                  </div>
                  <div className="flex flex-row hidden space-x-5">
                    <div className="w-1/2 py-5 rounded-lg bg-cyan-50">
                      <h2 className="text-xl font-bold text-center font-primary">
                        For 9 to 12 years old side (2 intakes)
                      </h2>
                      <h3 className="text-lg text-center font-primary">
                        Primary School Group
                      </h3>
                      <div className="py-5 text-center">
                        <p className="font-bold">
                          21 & 22 November 2024 (Thur & Fri)
                        </p>
                        <p>9.30 am to 6.00 pm on both days</p>
                        <p className="font-bold text-red-600">
                          Open for Registration!
                        </p>
                      </div>
                      <div className="py-5 text-center">
                        <p className="font-bold">
                          5 & 6 December 2024 (Thur & Fri)
                        </p>
                        <p>9.30 am to 6.00 pm on both days</p>
                        <p className="font-bold text-red-600">
                          Open for Registration!
                        </p>
                      </div>
                      <div className="py-5 text-center">
                        <p className="font-bold">
                          10 & 11 December 2024 (Tue & Wed)
                        </p>
                        <p>9.30 am to 6.00 pm on both days</p>
                        <p className="font-bold text-red-600">
                          Open for Registration!
                        </p>
                      </div>
                    </div>
                    <div className="w-1/2 py-5 bg-orange-100 rounded-lg">
                      <h2 className="text-xl font-bold text-center font-primary">
                        For 13 to 18 years old side (2 intakes)
                      </h2>
                      <h3 className="text-lg text-center font-primary">
                        Sec School/Teen Group
                      </h3>
                      <div className="py-5 text-center">
                        <p className="font-bold">
                          28 & 29 November 2024 (Thur & Fri)
                        </p>
                        <p>9.30 am to 6.00 pm on both days</p>
                        <p className="font-bold text-red-600">
                          Open for Registration!
                        </p>
                      </div>
                      <div className="py-5 text-center">
                        <p className="font-bold">
                          12 & 13 December 2024 (Thur & Fri)
                        </p>
                        <p>9.30 am to 6.00 pm on both days</p>
                        <p className="font-bold text-red-600">
                          Open for Registration!
                        </p>
                      </div>
                    </div>
                  </div>
                  <p class="py-5 font-text text-lg text-center font-bold hidden">
                    (doors open for attendance from 9 to 9.30 am for both
                    sessions, with class starting strictly at 9.30 am)
                  </p>
                  <div className="w-full p-5 my-10 bg-gray-100 rounded-lg">
                    <h2 className="text-xl font-bold text-center font-primary">
                      ***Schedules to be confirmed
                    </h2>
                    <h2 className="text-xl font-bold text-center font-primary">
                      Price Details
                    </h2>
                    <h3 className="py-3 text-2xl text-center font-text">
                      We are offering a limited seats-only rate of – S$459 nett
                    </h3>
                    <h3 className="text-lg text-center font-text">
                      We are offering a limited seats-only rate of – S$459 nett
                      (all materials, evaluation, certificates included)
                    </h3>
                  </div>
                  <div className="my-10 text-center">
                    <NavLink
                      className="px-4 py-4 text-md lg:text-xl font-bold text-white font-primary bg-btnColor rounded-xl"
                      to="https://docs.google.com/document/d/1vRGposm0rlhUid5RJZVUv-2_trEvyQtkdFKyVe3apsA/edit?usp=sharing"
                    >
                      Click here to book your slot! (instructions on next page)
                    </NavLink>
                  </div>
                </section>
                <section>
                  <h2 className="my-10 text-2xl font-bold text-center">
                    Other Key Programme Details
                  </h2>

                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      Where is the class conducted?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      <b>SAFRA TAMPINES BRANCH</b>
                      <br />
                      Public Speaking Academy @ Tampines <br />
                      1A Tampines Street 92 #01-13B <br />
                      SAFRA Tampines <br />
                      Singapore-528882.
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      What is the class size?
                      <br />
                      Can I attend a trial?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      Maximum class size of 17 students – as this is an
                      intensive programme with limited capacity, there is no
                      trial class. Likewise, only enrolled students will be
                      allowed to sit in for the programme to ensure a
                      constructive and safe learning environment.
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      Who are the Trainers?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      The programme will be helmed by our Principal/ World
                      Champion of Public Speaking Mr Darren Tay, and our senior
                      trainer (with each Trainer taking one day each)
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      What if My child Misses a Class or needs to leave early?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      There is no replacement for the missed session or a
                      refund/pro-rate for the session/portions missed. We
                      recommend parents/students to confirm their availability
                      for the full programme before signing up (you may instead
                      consider our regular public speaking programme).
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-y border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white text-textColor">
                      How are your classes conducted with the present COVID-19
                      situation?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100 text-textColor">
                      Our programmes are conducted with proper clearance from
                      the relevant Government authorities (Ministry of Education
                      and EnterpriseSG). We also abide by our Safety Management
                      Plan which measures such as safe distancing, mandatory
                      mask-wearing, temperature-taking etc. (feel free to
                      request for a copy of our Safety Management Plan).
                      <br />
                      <br />
                      In the event of any COVID-19 measures, legislation, or any
                      directive from the Government disrupting the conduct of
                      the programme, there will be no refund of the fees paid.
                      Instead, the programme will be postponed to another
                      date/time (as per our Terms & Conditions in our
                      Registration Form).
                    </div>
                  </div>
                </section>
                <section>
                  <h2 className="my-10 text-2xl font-bold text-center">
                    Find out more about our Track Record since 2009!
                  </h2>
                  <img
                    className="my-3"
                    src="/images/image showing satisfied corporate clients of s'peak performance public speaking skills training program-min.png"
                    alt=""
                  />
                  <img
                    className="my-3 text-center"
                    src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-3-3.jpg"
                    alt=""
                  />
                </section>
                <section>
                  <div className="my-10">
                    <p class="text-lg font-bold pb-5">
                      Tailored for Real-World Success
                    </p>
                    <p class="font-text text-lg">
                      The SPP holiday camp’s curriculum equips young learners
                      with skills they can apply in school, at home, and in
                      their future pursuits. With a focus on interview-specific
                      techniques, such as:
                    </p>
                    <ul className="p-5 list-disc list-outside">
                      <li className="pb-5 text-lg font-text">
                        Swiftly Formulate responses to common interview
                        questions
                      </li>
                      <li className="pb-5 text-lg font-text">
                        Competently handle tough and not-so-common interview
                        questions
                      </li>
                      <li className="pb-5 text-lg font-text">
                        Communicating personal strengths and achievements
                        (self-pitching) effectively
                      </li>
                      <li className="pb-5 text-lg font-text">
                        Managing nervousness and projecting confidence
                      </li>
                      <li className="pb-5 text-lg font-text">
                        Building rapport with interviewers through active
                        listening and engaging communication
                      </li>
                    </ul>
                    <p class="font-text text-lg">
                      This programme is the perfect preparation for key moments
                      like school admission interviews, scholarship panels, and
                      leadership selection boards. Under the guidance of
                      Singapore’s first Public Speaking World Champion and our
                      experienced senior trainers, your child will gain the
                      tools to shine in any interview setting.
                    </p>
                    <p class="font-text text-lg">
                      Give your child the interview Advantage this March
                      holiday!
                    </p>
                    <div className="my-10 text-center">
                      <NavLink
                        className="px-4 py-4 text-md lg:text-xl font-bold text-white font-primary bg-btnColor rounded-xl"
                        to="https://docs.google.com/document/d/1vRGposm0rlhUid5RJZVUv-2_trEvyQtkdFKyVe3apsA/edit?usp=sharing"
                      >
                        Click here to book your slot! (instructions on next
                        page)
                      </NavLink>
                    </div>
                    <p class="text-lg font-bold py-3">
                      If you have any queries before signing up, please send us
                      an email (to: contact@publicspeakingacademy.com.sg) with
                      “SPP 2024 Kids” in the email header and we will get back
                      to you on your query!
                    </p>
                    <p class="font-text text-lg py-3">
                      If you are keen to develop your child into a confident,
                      competent, and creative speaker/communicator/leader these
                      school holidays, join us for the S’Peak Performance
                      Programme (our signature Public Speaking Holiday Camp for
                      Kids aged 9-18)! Our founder, and 2016 World Champion of
                      Public Speaking Darren Tay, looks forward to sharing his
                      tried and tested know-hows, techniques, and experiences
                      with your child/children, in his signature school holiday
                      camp, to help your child enhance his/ her presentation
                      abilities and boost your child/ children’s public speaking
                      confidence!
                    </p>
                    <p class="font-text text-lg py-3">
                      If you are also looking for public speaking courses or
                      holiday workshop for yourself (adults), you may check out
                      our{" "}
                      <NavLink
                        className="font-text text-lg text-btnColor hover:underline"
                        to="/courses-and-programs"
                      >
                        public speaking courses for adults here.
                      </NavLink>
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div className="hidden md:inline md:w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
